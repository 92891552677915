exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blogs-detail-post-jsx": () => import("./../../../src/pages/blogs/Detail-Post.jsx" /* webpackChunkName: "component---src-pages-blogs-detail-post-jsx" */),
  "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-1-mdx": () => import("./../../../src/pages/blogs/Detail-Post.jsx?__contentFilePath=/opt/buildhome/repo/src/content/post-1.mdx" /* webpackChunkName: "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-1-mdx" */),
  "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-2-mdx": () => import("./../../../src/pages/blogs/Detail-Post.jsx?__contentFilePath=/opt/buildhome/repo/src/content/post-2.mdx" /* webpackChunkName: "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-2-mdx" */),
  "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-3-mdx": () => import("./../../../src/pages/blogs/Detail-Post.jsx?__contentFilePath=/opt/buildhome/repo/src/content/post-3.mdx" /* webpackChunkName: "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-3-mdx" */),
  "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-4-mdx": () => import("./../../../src/pages/blogs/Detail-Post.jsx?__contentFilePath=/opt/buildhome/repo/src/content/post-4.mdx" /* webpackChunkName: "component---src-pages-blogs-detail-post-jsx-content-file-path-src-content-post-4-mdx" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

